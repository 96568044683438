import React, { useState, useEffect } from 'react'
import { Box, Flex, Text, useColorMode } from '@chakra-ui/react'
import PopularCommunities from './components/PopularCommunities'
import { TrendingRaffles } from './components/TrendingRaffles'
import DAppLayout from './layouts/DAppLayout'
import LandingPageRaffles from './components/LandingPageRaffles'
import { FloatingButton } from '../rewards'
import { RewardsModal } from '../rewards'
import {
  Notification,
  NotificationHeader,
} from '../admin/components/Notification'

const DAppLandingPage = () => {
  const [loading, setLoading] = useState(true)
  const { colorMode, setColorMode } = useColorMode()
  const isDarkMode = colorMode === 'dark'
  const [lastPopupTime, setLastPopupTime] = useState(0) // Initialize as a timestamp
  const [showPopup, setShowPopup] = useState(false)
  const [doNotShowAgain, setDoNotShowAgain] = useState(false) // Initialize the new state

  useEffect(() => {
    // Retrieve values from localStorage
    const storedLastPopupTimeStr = localStorage.getItem('lastPopupTime')
    const storedDoNotShow = localStorage.getItem('doNotShowAgain') === 'true'

    // Parse the retrieved values or default to 0 if they are null
    const storedLastPopupTime = storedLastPopupTimeStr
      ? parseInt(storedLastPopupTimeStr, 10)
      : 0

    setLastPopupTime(storedLastPopupTime)
    setDoNotShowAgain(storedDoNotShow)
    setLoading(false) // Mark data as loaded
  }, [])

  useEffect(() => {
    if (colorMode === 'light') {
      setColorMode('dark')
    }
  }, [])

  useEffect(() => {
    if (!loading) {
      const currentTime = Date.now() // Get current time in milliseconds

      // Check if it's been at least 15 minutes since the last popup and not opted to hide the popup
      if (!doNotShowAgain && currentTime - lastPopupTime >= 15 * 60 * 1000) {
        setShowPopup(true)
      }
    }
  }, [lastPopupTime, loading, doNotShowAgain])

  const handleClosePopup = () => {
    setShowPopup(false)

    // Update the last popup time to the current time
    const currentTime = Date.now()
    localStorage.setItem('lastPopupTime', currentTime.toString())
    setLastPopupTime(currentTime)
  }

  const handleDoNotShowAgain = () => {
    localStorage.setItem('doNotShowAgain', 'true')
    setDoNotShowAgain(true)
    setShowPopup(false)
  }

  return (
    <DAppLayout>
      <FloatingButton />
      <Flex justifyContent={'center'} marginY='1.5rem' paddingX='.4rem'>
        <Flex
          justifyContent={'center'}
          borderRadius='20px'
          background={
            isDarkMode
              ? 'radial-gradient(circle at top left, #232323, #181818)'
              : 'cardWhite'
          }
          border='1px solid'
          borderColor={isDarkMode ? '#494949' : '#E9E9E9'}
          paddingY='1rem'
          paddingX={['1rem', '1.5rem']}
          gap={['12px', '24px']}
        >
          <Flex alignItems={'center'}>
            <Text fontSize={['1.5rem', '2rem']} fontWeight={600}>
            💯
            </Text>
          </Flex>
          <Box>
            <Text
              w='100%'
              textAlign={'left'}
              fontSize={['1rem', '1.2rem']}
              fontWeight={500}
            >
              Monet Madness is here!
              <br />Flash Raffles only running for 30 minutes, 2-3X point farming and insane rewards.
            </Text>
          </Box>
        </Flex>
      </Flex>
      <TrendingRaffles />
      <PopularCommunities />
      <Box mt={['5rem', '18rem']}>
        {showPopup && (
          <RewardsModal
            showPopup={showPopup}
            handleClosePopup={handleClosePopup}
            handleDoNotShowAgain={handleDoNotShowAgain}
          />
        )}
        <LandingPageRaffles />
      </Box>
    </DAppLayout>
  )
}

export default DAppLandingPage
