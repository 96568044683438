import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Link,
  Text,
} from '@chakra-ui/react'
import Lottie from 'react-lottie-player'
import communityLottie from '../assets/animations/community.json'
import projectLottie from '../assets/animations/project.json'
import DAppLandingPage from '../modules/dApp/DAppLandingPage'

export default function MonetLanding() {
  return <DAppLandingPage />

  return (
    <Box
      minHeight={'100vh'}
      //background='#F3F3F5'
    >
      <Container
        maxW={'100rem'}
        pt='2rem'
        px='1rem'
        pb='3rem'
        fontFamily='Montserrat'
      >
        <Flex justifyContent='space-between'>
          <Heading fontFamily='Playfair Display'>Monet</Heading>
          <Link href='https://forms.gle/RL1uVpna3cSL7RoZ7' target='_blank'>
            <Button
              bgColor='black'
              color='white'
              p='1.35rem'
              border='2px solid black'
              _hover={{
                bgColor: '#333',
              }}
            >
              Pre-Register
            </Button>
          </Link>
        </Flex>

        <Box mt='3rem'>
          <Heading textAlign='center' fontSize='2.5rem' fontWeight='700'>
            Raffles as a Service <br />
            for DAOs and Holders.
          </Heading>
          <Text fontSize='1.5rem' textAlign='center' fontWeight='500'>
            Raffles for and from your community.
            <Text
              as='span'
              fontStyle='italic'
              fontSize='1.5rem'
              textAlign='center'
              fontWeight='500'
            >
              {' '}
              For free.
            </Text>
          </Text>

          <Flex direction={['column', 'row']} mt='4rem' justifyContent='center'>
            <Box
              backgroundColor='#fafafa'
              borderRadius='10px'
              width={['100%', '50%']}
              maxWidth='25rem'
              border='2px solid white'
              p='2rem'
              mr={[0, '1rem']}
              mb={['1rem', 0]}
            >
              <Lottie
                play
                loop
                animationData={projectLottie}
                speed={0.54}
                style={{ width: '80%', maxWidth: '12rem', margin: '1rem auto' }}
              />

              <Text fontSize='1.25rem' fontWeight='600' mt='2.5rem'>
                Project Raffles
              </Text>
              <Text>
                Raffle NFTs and Whitelists. <em>For free.</em>
              </Text>
            </Box>

            <Box
              backgroundColor='#fafafa'
              borderRadius='10px'
              width={['100%', '50%']}
              maxWidth='25rem'
              p='2rem'
              border='2px solid white'
            >
              <Lottie
                play
                loop
                speed={0.5}
                animationData={communityLottie}
                style={{ width: '80%', maxWidth: '12rem', margin: '1rem auto' }}
              />
              <Text fontSize='1.25rem' fontWeight='600' mt='2.5rem'>
                Community Raffles
              </Text>
              <Text>Let your Community create Raffles.</Text>
            </Box>
          </Flex>

          <Flex direction={['column', 'row']} mt='2rem' justifyContent='center'>
            <Box mr='1rem' w={['100%', '50%']} textAlign={['center', 'right']}>
              <Link href='https://forms.gle/RL1uVpna3cSL7RoZ7' target='_blank'>
                <Button
                  bgColor='black'
                  color='white'
                  p='1.35rem'
                  border='2px solid black'
                  _hover={{
                    bgColor: '#333',
                  }}
                >
                  Pre-Register
                </Button>
              </Link>
            </Box>

            <Box
              w={['100%', '50%']}
              textAlign={['center', 'left']}
              mt={['1rem', 0]}
            >
              <Link
                href='https://sac-nft.notion.site/MONET-Raffle-Platform-7abd3e9f3eb64079a709ee4d70930985'
                target='_blank'
              >
                <Button
                  border='2px solid black'
                  color='black'
                  bg='transparent'
                  p='1.35rem'
                  _hover={{
                    bg: '#333',
                    color: 'white',
                  }}
                >
                  Read Docs
                </Button>
              </Link>
            </Box>
          </Flex>

          <Text
            textAlign='center'
            mt={['5rem', '12rem']}
            fontSize='.75rem'
            fontWeight='600'
          >
            From the creators of{' '}
            <Link
              href='https://www.stonedapecrew.com'
              target='_blank'
              textDecor='underline'
              _hover={{
                color: '#888',
                textDecor: 'underline',
              }}
            >
              Stoned Ape Crew
            </Link>
          </Text>
        </Box>
      </Container>
    </Box>
  )
}
